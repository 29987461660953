<template>
    <section class="pqr-index ml-4 mt-3">
        <tabs :tabs="arrayTabs" class="mx-0" />
        <router-view class="mt-4" />
    </section>
</template>
<script>
export default {
    data(){
        return{
        }
    },
    computed:{
        arrayTabs(){
            if (this.$usuario.tipo == 4){
                return [
                    {
                        titulo:'Solicitudes',
                        ruta:"pqr.solicitudes",
                    },
                    {
                        titulo:'Resueltas',
                        ruta:"pqr.resueltas",
                    },
                ]
            }else{
                return  [
                    {
                        titulo:'Solicitudes',
                        ruta:"pqr.solicitudes",
                    },
                    {
                        titulo:'Resueltas',
                        ruta:"pqr.resueltas",
                    },
                    {
                        titulo:'Resoluciones',
                        ruta:"pqr.resoluciones",
                    },
                ]
            }
        }
    }
}
</script>
<style lang="scss" scoped></style>